/* You can add global styles to this file, and also import other style files */
// ----------comman---scss-------start--------------------
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.form-control {
  height: 7vh;
  box-shadow: none;
  outline: none;
}
.content-wrapper{
  background: #F5F7FF;
 
}
.heading{
  color: #4B49AC;
  font-family: Nunito !important;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sub-heading{
  color: rgba(18, 26, 54, 0.98);
  font-family: Nunito;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.carousel-control-next, .carousel-control-prev{
  z-index: -1;
  width: 0%;
}
.carousel-caption{
  left: 15px !important;
  top:10px !important;
  padding: 0 !important
}
.carousel-indicators{
  margin-bottom: 0 !important;
}
.comman-btn1 {
  border: none;
  padding: .5rem 1rem .5rem 1rem;
  padding-right: 1rem;
  border-radius: 5px;
  color: #ffffff;
}

.comman-btn {
  border: none;
  padding: .5rem 1rem .5rem 1rem;
  border-radius: 5px;
  color: #ffffff;
}

.bi-search {
  font-size: 1.2rem;
  color: gray;
}

// ---------------------------------------
// ---------logout--modal-----------------
.modal-content {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.form-control-color {
  height: 10px !important;
}

// --------------------------------
.table-responsive-content {
  overflow: none;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.menu-item-1 {
  left: -4rem !important;

  li {
    cursor: pointer;
  }
}

.menu-item-2 {
  top: -.6rem !important;

  li {
    cursor: pointer;
  }
}

.collapse-data {
  cursor: pointer;
}

// ----------------------
.description {
  overflow-y: hidden;
  height: 10vh;
}

.error-message {
  position: absolute;
}

// --------------------
.bi-pencil-fill,
.bi-trash3-fill {
  cursor: pointer;
}

// ---------------------
.heading-section {
  i {
    cursor: pointer;
  }

  span {
    margin-left: 2rem;
  }

  h3 {
    margin-top: .65rem;
  }
}

// --------media-start---------------
@media screen and (max-width: 900px) {
  .side-section {
    width: 100% !important;
  }
}

@media screen and (max-width: 1000px) {
  .side-section {
    width: 100% !important;
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// ------------end----------------

// .main-panel{
//   height: 600px;
// }

.active-link{
  background-color: #4B49AC;
  color: white !important;
}
// ----------------------------
// .add-server-modal{
//   width: 800px !important;
// }

.pointer {
  cursor: pointer;
}

/* width */
// ::-webkit-scrollbar {
//   width: 7px;
//   height: 7px !important;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1; 
// }
 
/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888; 
//   border-radius: 16px;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555; 
// }
// ----------@media---------------

@media screen and (max-width: 820px) {
  .responsive-field {
    width: 95%;
  }
  .responsive-field-1{
      width: 95%;
     margin-left: 1rem;
     margin-top: .5rem;
     margin-bottom: 0 !important;
  }

  .col-form-label {
      padding-top: 0 !important; 
      padding-bottom: 0 !important; 
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1;
  }
.px-3{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Select-Duration{
  margin-top: 1rem;
}
.custom-select{
  height: 4.8vh !important;
}
.show-nav{
  width: 30% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.hide-nav{
  width: 46% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.login-form{
  width: 100% !important;
}
}

@media screen and (max-width: 480px) {
  .responsive-field {
    width: 95%;
  }
  .responsive-field-1{
      width: 95%;
     margin-left: 1rem;
     margin-top: .5rem;
     margin-bottom: 0 !important;
  }

  .col-form-label {
      padding-top: 0 !important; 
      padding-bottom: 0 !important; 
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1;
  }
.px-3{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.custom-select{
  height: 4.8vh !important;
}
.Select-Duration{
  margin-top: 1rem;
}
.show-nav{
  width: 60% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.hide-nav{
  width: 60% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.login-form{
  width: 100% !important;
}
.brand-logo{
  img{
    width: 25% !important;
  }
}

}
.table-responsive{
  min-height: 38vh;
  max-height: 50vh;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
